.layout {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

#video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.5);
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#small {
  position: absolute;
  bottom: 0;
  color: rgb(255, 255, 255, 0.3);
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

#attribution {
  position: absolute;
  top: 0 !important;
  right: 10px;
  color: rgb(255, 255, 255, 0.1);
  font-size: 0.6rem;
  margin-top: 0.5rem;
}