#screenshots {
  color: white;
  padding: 0 1rem;
}

#screenshots h5 {
  margin: 0;
}

#ss-img {
  display: flex;
  width: 100%;
  height: auto;
}

.original-layout,
.new-images {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.arrow-buttons button {
  background-color: rgba(49, 145, 255, 0.1);
  color: white;
  border: none;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  margin: 0 10px;
}

.arrow-buttons button:hover {
  background-color: rgba(141, 194, 255, 0.2);
}

#Fa-arr {
  color: white;
}

.new-images div {
  display: flex;
}

#ss-layout {
  overflow-y: auto;
  height: 500px;
}

/* MEDIA QUERIES */

@media only screen and (min-width: 768px) {
  .original-layout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
  }

  .original-layout > div:nth-child(3) {
    display: flex;
    grid-column: 2;
    grid-row: 1 / span 2;
  }

  #ss-layout {
    height: 600px;
  }
  
}