nav {
  flex-direction: column;
}

nav small {
  color: rgb(177, 197, 0);
}

nav h2 {
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 1rem;
}

.nav {
  gap: 1rem;
  margin-bottom: 1.5rem !important;
}

svg {
  color: rgba(255, 255, 255, 0.5);
  height: 1.2rem;
  width: 1.2rem;
  transition: color 0.2s ease-in-out, transform 0.15s ease-in-out;
}

svg:hover {
  color: white;
}

/* QUERIES */

@media only screen and (min-width: 375px) {

  nav {
    flex-direction: row;
    justify-content: space-between !important;
    width: 95%;
  }

  nav h2,
  .nav {
    margin-bottom: 0.5rem !important;
  }

}