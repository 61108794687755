#privacy-policy {
  overflow-y: auto;
  height: 70vh;
  padding: 0.5rem;
}

#privacy-policy section {
  padding: 0.5rem;
}

#privacy-policy span {
  color: rgb(138, 251, 255);
}

@media only screen and (min-width: 768px) {
  #privacy-policy {
    height: 100%;
    padding: 1rem;
  }

}