.bot-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.ml-2 {
  margin: 0 10px;
}

.custom-bg-1 {
  background-color: rgba(15, 17, 31, 0.6) !important;
}

.custom-bg-2 {
  background-color: rgba(53, 81, 109, 0.6) !important;
  width: 100%;
}