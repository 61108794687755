#documentation > div {
  overflow-y: auto;
  height: 70vh;
  padding: 0.5rem;
}

#documentation section {
  padding: 0.5rem;
}

.features {
  background-color: #06151a93;
  padding: 0.5rem 1rem;
  border-radius: 3px;
}

li code {
  border: none;
  padding: 0.3rem 0.6rem;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.411);
}

.apis {
  border: 1px solid #23789793;
  padding: 0.5rem 1rem;
  border-radius: 3px;
}

#api-link {
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  #region-api {
    margin-top: 1rem;
    flex-direction: row !important;
    gap: 0.5rem;
  }

  #regions {
    justify-content: space-between !important;
  }

  #documentation > div {
    height: 100%;
    padding: 1rem;
  }

}