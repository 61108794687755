.card {
  background-color: #0c293193 !important;
  color: white !important;
  max-width: 95%;
  max-height: 70%;
}

.card-body {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

button {
  border: none;
  padding: 0.3rem 0.6rem;
  border-radius: 5px !important;
  transition: background-color 0.2s ease-in-out, transform 0.15s ease-in-out;
}

#code-btn-1 {
  background-color: rgba(0, 0, 0, 0.411);
  border: 1px solid rgb(49, 145, 255);
}

#code-btn-1:hover {
  background-color: rgba(49, 145, 255, 0.1);
}

#code-btn-2 {
  background-color: rgba(0, 0, 0, 0.411);
  border: 1px solid rgb(136, 255, 165);
}

#code-btn-2:hover {
  background-color: rgba(136, 255, 165, 0.1);
}

.card small {
  text-align: center;
}

code {
  color: rgb(255, 110, 134) !important;
}

.chat-output {
  flex: 1;
  padding-top: 10px;
  overflow-y: auto;
  height: 800px !important;
}

/* MEDIA QUERIES */

@media only screen and (min-width: 600px) {
  .card {
    width: 560px;
  }
}

@media only screen and (min-width: 768px) {
  .card {
   width: 720px; 
  }
}

@media only screen and (min-width: 1440px) {
  .card {
   width: 50vw !important; 
  }
}