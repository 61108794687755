/* MAINTENANCE & UPDATES */

#server-status small {
font-size: 0.8rem;
}

#server-status div:nth-child(2) {
  margin-bottom: 1rem;
}

/* CLASH */

#clash-info {
  background-color: rgba(28, 43, 58, 0.6) !important;
  border-radius: 3px;
  padding: 0.5rem;
}

#clash-info > div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

#clash-info > div > div {
  display: flex;
  flex-direction: column;
}

#clash-info span {
  background-color: rgba(20, 30, 41, 0.6);
  border-radius: 3px;
  padding: 0.3rem 0.6rem;
}

#cancelled {
  color: rgb(255, 109, 109);
}

/* REUSED */

#server-status,
#clash-status {
  padding: 0.2rem;
}

#server-status h5,
#clash-status h5 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

#maintenance,
#updates {
  background-color: rgba(28, 43, 58, 0.6) !important;
  padding: 0.5rem 0.5rem 0.2rem 0.5rem;
  border-radius: 3px;
}

/* QUERIES */

@media only screen and (min-width: 600px) {

  #server-status,
  #clash-status {
    padding: 0.5rem;
  }

  #clash-info > div > div {
    flex-direction: row;
    align-items: center;
  }
  
  #clash-info span {
    margin-left: 10px;
  }

}